<template>


  <UiHorizontalScroll
    :align="'cenleftter'"
  >
    
    <div :class="'tbs_menu touch_slider active_touch_slider ' + (centered ? 'centered' : '')">

      <template v-for="tab in list">            
        <NuxtLink 
          v-if="tab.id !== 'schemes_' || (tab.id === 'schemes' && tab.count>=0)"
          :to="tab.link" 
          @click.native="handleSetActive(tab.id)" 
          :class="'menu_item ' + (active == tab.id ? ' act active ' : '') + (tab?.class ?? '')" 
        >
          <div>{{ tab.tr ? $t(tab.tr) : tab.name }} </div>
          <span v-if="tab.count" class="lbl">{{ shortCounter(tab.count) }}</span>
          <i v-if="tab.icon" :class="tab.icon"></i>
        </NuxtLink>
      </template> 
  
    </div>
    
  </UiHorizontalScroll>
  
</template>

<script setup lang="ts">

import { shortCounter } from '@/types/other';
import { watchArray } from '@vueuse/core'

const route = useRoute();

const emits = defineEmits(['choose']);


interface Tab {
  id: string;
  name?: string;
  tr?: string;
  link: string;
  icon: string;
  count: number;
  class?: string;
}

interface Props {
  tabs: Tab[];
  acttab: string | null;
  centered?: boolean;
}

const props = defineProps<Props>();

var list = ref<Tab[]>(props.tabs);
var active = ref<string | null>(props.acttab);


const toggleActivePath = function(){     
  for(var l of list.value){        
    if(route.fullPath == l.id)
      active.value = l.id
  } 
}
const handleSetActive = function(id: string){       
  active.value = id;      
  emits('choose', id);
}

const getCurrentTabFromPath = function(){     
  for(var l of list.value){        
    if(route.fullPath == l.link)
      return l.id
  } 
  return null;
}
 

active.value = getCurrentTabFromPath();


watchArray([list], async ([new_list], added, removed) => {
  list.value = new_list;
  toggleActivePath();
}, {deep: true})



watch(() => props.tabs, (new_props) => {
  list.value = new_props;
}, { deep: true })
 
</script>

<style scoped>
 
 
 

.tbs_menu{
    height: 45px;
    /* overflow-x: auto;
    overflow-y: hidden; */
    width: 100%;
}
.tbs_menu::-webkit-scrollbar {
    display: none;
}
.tbs_menu .tbs{
  display: flex;
  white-space: nowrap;
  width: 100%;
    height: 43px;
}

.tbs_menu .menu_item{
  display: table-cell;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-radius: 0em;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 0em 0em -2px;
  padding: 0.7rem 1rem;
  border-bottom-width: 2px;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
  text-align: center;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-radius: 0em;
  cursor: pointer!important;
  /* text-overflow: ellipsis;
  overflow: hidden; */
  white-space: nowrap;
  /* width: 14.25%!important; */
  /* min-width: 123px; */
  cursor: pointer!important;
  /* max-width: 200px; */
  color: inherit;
}
.tbs_menu  .menu_item:hover,
.tbs_menu  .menu_item.act{
    border-bottom-color: var(--gd-button-primary-back-color);
    border-bottom-style: solid;
    border-radius: 0em;
}
.tbs_menu  .menu_item > .lbl{
  display: inline-block;
  min-height: auto;
  margin-left: 9px;
  min-width: auto;
  text-transform: inherit;
  background-color: transparent !important;
  padding: 0 !important;
  color: var(--un-text-color);
  font-weight: bold;
}

.tbs_menu  .menu_item.is-new::after{
  content: 'NEW';
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.4rem;
    display: block;
    color: #e31111;
    padding: 0 2px;
    border-radius: 2px;
    line-height: 0.6rem;
    font-style: normal;
    display: inline-block;
    margin-top: -10px;
    vertical-align: middle;
    margin-right: -10px;

}
.tbs_menu  .menu_item i,
.tbs_menu  .menu_item div{
    display: inline-block;
    font-size: 0.85rem;
}
.tbs_menu  .menu_item div{
font-weight: bold;
}

.centered{

    width: fit-content;
    margin: 0 auto;
}
</style>
